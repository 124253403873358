<template>
  <div class="notif-container">
    <template v-if="!hasLoaded && !hasNotification">
      <div class="d-flex h-100 w-100 flex-column align-center justify-center text-center">
        <img src="@/assets/images/notification-empty.png" height="400" class="mb-10" />
        <h2 class="mb-2">No Notification</h2>
        There is no notification right now. Please come back later.
      </div>
    </template>
    <template v-else>
      <div class="notif-list">
        <div class="notif-search">
          <v-text-field
            outlined
            dense
            placeholder="Search..."
            single-line
            class="rounded-lg mt-2"
            :hide-details="true"
            clearable
            background-color="#fff"
            prepend-inner-icon="mdi-magnify"
            v-model="search"
          />
        </div>
        <div class="notif-list-content">
          <div class="notif-list-content-inner">
            <v-list two-line :disabled="notificationLoading">
              <v-list-item-group v-model="selected" color="primary">
                <template v-if="notificationLoading">
                  <center class="py-10">
                    <v-progress-circular indeterminate />
                  </center>
                </template>
                <template v-else-if="!notificationLoading & hasNotification" v-for="(item, index) in notifications.results">
                  <v-list-item :key="item.id" class="py-3 pl-2">
                    <template>
                      <v-list-item-content>
                        <v-list-item-title class="notif-title mb-2" :class="{ 'pl-4': item.read_at }">
                          <div class="d-flex align-center justify-star text-wrap">
                            <v-icon v-if="!item.read_at" size="8" color="orange accent-3" class="mr-2">
                              mdi-checkbox-blank-circle
                            </v-icon>
                            {{ item.notification.title }}
                          </div>
                        </v-list-item-title>
                        <v-list-item-subtitle class="notif-content text-wrap pl-4" v-text="item.notification.message" />
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-list-item-action-text v-text="parseToTimeByTimezone(item.created_at)" />
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                  <v-divider :key="index" class="item-divider" />
                </template>
                <template v-else>
                  <div class="pa-4 text-center">Notification Empty</div>
                </template>
              </v-list-item-group>
            </v-list>
          </div>
        </div>
        <div class="notif-pagination pt-2">
          <v-pagination v-model="currentPage" :length="dataLength" />
        </div>
      </div>
      <div class="notif-main-content py-13 px-11">
        <template v-if="notificationDetailLoading">
          <v-progress-circular indeterminate />
        </template>
        <template v-else-if="detail && !notificationDetailLoading">
          <v-btn icon color="red" class="btn-delete" @click="onDeleteConfirm">
            <v-icon>mdi-trash-can-outline</v-icon>
          </v-btn>
          <h2>{{ detail.title }}</h2>
          <span class="time">
            {{ utilityMixin_formatDateTime(notificationDetail.created_at) }}
          </span>
          <div class="my-6 cntn">{{ detail.message }}</div>
          <v-btn large depressed color="primary rounded-lg px-10 no-transform" @click="goToLink">
            Show Me
          </v-btn>
        </template>
        <template v-else>
          <div class="d-flex flex-column align-center justify-center text-center h-100">
            <SvgIcon icon="notification-not-selected" width="300px" height="auto" class="mb-8" />
            <h2 class="mb-2">No Notification Selected Yet</h2>
            <p>
              Notifications will appear here when you select one from the list of notifications on the left side
            </p>
          </div>
        </template>
      </div>
    </template>
    <base-alert ref="alert" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import debounce from 'lodash/debounce';
import moment from 'moment-timezone';
import utilityMixins from '@/mixins/utilityMixin';

export default {
  name: 'Notifications',
  mixins: [utilityMixins],
  data: () => ({
    currentPage: 1,
    search: null,
    selected: null,
    hasLoaded: false,
  }),
  computed: {
    ...mapGetters({
      notifications: 'Notification/notifications',
      notificationLoading: 'Notification/notificationLoading',
      notificationDetail: 'Notification/notificationDetail',
      notificationDetailLoading: 'Notification/notificationDetailLoading',
      notificationParams: 'Notification/notificationParams',
    }),
    hasNotification() {
      return this.notifications.results.length > 0;
    },
    detail() {
      return this.notificationDetail?.notification ?? null;
    },
    dataLength() {
      return Math.ceil(this.notifications.count / 10);
    },
  },
  watch: {
    selected(index) {
      if (index !== null) {
        const id = this.notifications.results?.[index]?.id ?? null;
        if (!id) return;
        this.$store.dispatch('Notification/fetchNotification', id).then(res => {
          const isReaded = Boolean(res.data.read_at);
          if (!isReaded) {
            this.$store.dispatch('Notification/updateNotificationRead', {
              id,
              read_at: moment().format('YYYY-MM-DD'),
            });
          }
        });
      } else {
        this.$store.commit('Notification/SET_NOTIFICATION_DETAIL', null);
      }
    },
    search: debounce(function(search) {
      this.$store.commit('Notification/SET_NOTIFICATION_PARAMS', { search });
    }, 300),
    currentPage(page) {
      const offset = (page - 1) * 10;
      this.$store.commit('Notification/SET_NOTIFICATION_PARAMS', { offset });
    },
    notificationParams: {
      deep: true,
      handler() {
        this.selected = null;
        this.getNotifications();
      },
    },
  },
  methods: {
    getNotifications() {
      this.$store.dispatch('Notification/fetchNotifications').then(res => {
        if (!this.hasLoaded && res.data.count > 0) {
          this.hasLoaded = true;
        }
      });
    },
    goToLink() {
      const url = new URL(this.detail.link);
      const newUrl = url.href.replace(url.origin, '');
      this.$router.push({ path: newUrl });
    },
    parseToTimeByTimezone(item) {
      let timezone = localStorage.getItem('timezone') || 'Asia/Jakarta';
      return moment(item)
        .tz(timezone)
        .format('HH:mm');
    },
    onDeleteNotification() {
      if (this.notificationDetail) {
        this.$store.dispatch('Notification/deleteNotification').then(() => {
          this.selected = null;

          this.$refs.alert.open({
            type: 'confirm',
            icon: 'success',
            cancelBtn: false,
            btnAgreeText: 'OK',
            message: 'Congratulations! You have successfully deleted one notification',
          });
        });
      }
    },
    onDeleteConfirm() {
      this.$refs.alert
        .open({
          type: 'confirm',
          icon: 'delete',
          cancelBtn: true,
          message: 'Are you sure you want to delete this notification ?',
        })
        .then(confirm => {
          if (confirm) this.onDeleteNotification();
        });
    },
  },
  created() {
    this.$store.commit('Notification/SET_NOTIFICATION_DETAIL', null);
    this.$store.commit('Notification/RESET_NOTIFICATION_PARAMS');
  },
};
</script>

<style lang="scss" scoped>
.notif-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  .notif-list {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;
    border-bottom: 4px solid #333;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.09);

    .notif-list-content {
      position: relative;
      flex: 1;

      &-inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        overflow-x: auto;
      }
    }

    .notif-search {
      padding: 10px;
      padding-bottom: 0;

      ::v-deep .v-input > .v-input__control > .v-input__slot > .v-input__prepend-inner > .v-input__icon > .v-icon {
        margin-left: 6px;
        margin-right: 12px;
        font-size: 18px;
        color: #dfddce;
      }
    }

    .notif-title {
      color: #16325c;
      font-family: Arial;
      font-size: 14px;
      font-weight: 600;
    }

    .notif-content {
      font-size: 13px;
      line-height: 20px;
    }

    .item-divider {
      background: #bddaef;
    }

    @include media-breakpoint-up(md) {
      width: 350px;
      flex: 0 0 350px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.07);
      border-bottom: none;
    }
  }

  .notif-main-content {
    flex: 1;
    overflow-y: auto;
    background-color: #fff;
    background-image: url('../../assets/images/background/notif-bg.png');
    background-position: right bottom;
    background-repeat: no-repeat;

    @include media-breakpoint-up(md) {
      flex: auto;
    }

    h2 {
      color: #16325c;
    }

    .time {
      font-size: 13px;
      color: #717c8b;
    }

    .cntn {
      font-size: 15px;
      color: #16325c;
    }

    .btn-delete {
      position: absolute;
      right: 30px;
    }
  }

  .no-transform {
    text-transform: none;
  }
}
</style>
